import React, { useRef, useState, useEffect } from 'react';
import { Stage, Layer, Image, Text, Transformer } from 'react-konva';
import styled from 'styled-components';
import { Button, Row, Col } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import useImage from 'use-image';

const CanvasContainer = styled.div`
  height: 0;
  padding-top: 33.33%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledStage = styled(Stage)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Icon = styled.img`
  width: 80%;
`;

const SpacedCol = styled(Col)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
  width: auto;
`;

const FooterText = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: rgba(255,255,255,.4);
  font-size: ${isMobile ? '5px' : '14px'};
  pointer-events: none;
  font-family: 'Lilita One';
  text-align: center;
`;

const CanvasComponent = ({ stickerObjects, setStickerObjects, background, text, setText }) => {
  const stageRef = useRef(null);
  const containerRef = useRef(null);
  const [backgroundImage] = useImage(background);
  const [selectedShapeName, setSelectedShapeName] = useState(null);
  const trRef = useRef();

  useEffect(() => {
    const fitStageIntoParentContainer = () => {
      const container = containerRef.current;
      if (!container) return;

      const containerWidth = container.offsetWidth;
      const scale = containerWidth / 1500;
      stageRef.current.width(1500 * scale);
      stageRef.current.height(500 * scale);
      stageRef.current.scale({ x: scale, y: scale });
    };

    fitStageIntoParentContainer();
    window.addEventListener('resize', fitStageIntoParentContainer);

    // Ensure resize after initial render
    const timeoutId = setTimeout(fitStageIntoParentContainer, 100);

    return () => {
      window.removeEventListener('resize', fitStageIntoParentContainer);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (trRef.current && selectedShapeName) {
      const node = stageRef.current.findOne(`.${selectedShapeName}`);
      if (node) {
        trRef.current.nodes([node]);
        trRef.current.getLayer().batchDraw();
      }
    }
  }, [selectedShapeName]);

  const handleExport = () => {
    const stage = stageRef.current;
    const originalWidth = stage.width();
    const originalHeight = stage.height();
    const scale = originalWidth / 1500;

    const prevSelectedShapeName = selectedShapeName;
    if (trRef.current) {
      setSelectedShapeName(null);
      trRef.current.nodes([]);
    }

    stage.width(1500);
    stage.height(500);
    stage.scale({ x: 1, y: 1 });

    stage.toDataURL({
      callback: (dataUrl) => {
        const link = document.createElement('a');
        link.download = 'twitter-banner.png';
        link.href = dataUrl;
        link.click();

        stage.width(originalWidth);
        stage.height(originalHeight);
        stage.scale({ x: scale, y: scale });

        if (prevSelectedShapeName) {
          setSelectedShapeName(prevSelectedShapeName);
          const node = stage.findOne(`.${prevSelectedShapeName}`);
          if (node && trRef.current) {
            trRef.current.nodes([node]);
            trRef.current.getLayer().batchDraw();
          }
        }
      },
    });
  };

  const handleStageMouseDown = (e) => {
    if (e.target === stageRef.current) {
      setSelectedShapeName(null);
      return;
    }
    const clickedOnTransformer = e.target.getParent().className === 'Transformer';
    if (!clickedOnTransformer) {
      const name = e.target.name();
      setSelectedShapeName(name);
      console.log("selected shape");
    }
  };

  const moveForward = () => {
    const layer = stageRef.current.getLayers()[0];
    const node = layer.findOne(`.${selectedShapeName}`);
    if (node) {
      node.moveUp();
      layer.draw();
    }
  };

  const moveBackward = () => {
    const layer = stageRef.current.getLayers()[0];
    const node = layer.findOne(`.${selectedShapeName}`);
    if (node) {
      node.moveDown();
      layer.draw();
    }
  };

  const flipHorizontal = () => {
    const layer = stageRef.current.getLayers()[0];
    const node = layer.findOne(`.${selectedShapeName}`);
    if (node) {
      const scaleX = node.scaleX();
      node.scaleX(-scaleX);

      const width = node.width();
      const x = node.x();

      node.x(x + width * scaleX);

      const offsetX = node.offsetX();
      const offsetY = node.offsetY();
      node.offsetX(offsetX * -1);
      node.offsetY(offsetY);

      layer.draw();
    }
  };

  const duplicate = () => {
    const layer = stageRef.current.getLayers()[0];
    const node = layer.findOne(`.${selectedShapeName}`);
    if (node) {
      const clone = node.clone();
      clone.position({ x: node.x() + 20, y: node.y() + 20 });
      const newId = `sticker-${Date.now()}`;
      clone.name(newId);
      setStickerObjects((prevStickerObjects) => [
        ...prevStickerObjects,
        { id: newId, image: clone.image(), x: clone.x(), y: clone.y() },
      ]);
      layer.draw();
      setSelectedShapeName(newId);
    }
  };

  const deleteObject = () => {
    const layer = stageRef.current.getLayers()[0];
    const node = layer.findOne(`.${selectedShapeName}`);
    if (node) {
      node.destroy();
      setSelectedShapeName(null);
      setStickerObjects((prevStickerObjects) =>
        prevStickerObjects.filter((sticker) => sticker.id !== node.name())
      );
      layer.draw();
    }
  };

  return (
    <div>
      <Row style={{ marginBottom: '10px' }}>
        <BrowserView>
          <SpacedCol>
            <Button style={{ width: "8%", backgroundColor: "#5E92F5" }} onClick={moveForward}>
              <Icon src={"/img/Buttons/Forward.png"} />
            </Button>
            <Button style={{ width: "8%", backgroundColor: "#5E92F5" }} onClick={moveBackward}>
              <Icon src={"/img/Buttons/Backward.png"} />
            </Button>
            <Button style={{ width: "8%", backgroundColor: "#5E92F5" }} onClick={flipHorizontal}>
              <Icon src={"/img/Buttons/Mirror.png"} />
            </Button>
            <Button style={{ width: "8%", backgroundColor: "#5E92F5" }} onClick={duplicate}>
              <Icon src={"/img/Buttons/Duplicate.png"} />
            </Button>
            <Button style={{ width: "8%", backgroundColor: "#5E92F5" }} onClick={deleteObject}>
              <Icon src={"/img/Buttons/Trash.png"} />
            </Button>
            <Button style={{ width: "8%", backgroundColor: "#5E92F5" }} onClick={handleExport}>
              <Icon src={"/img/Buttons/Save.png"} />
            </Button>
          </SpacedCol>
        </BrowserView>
        <Col></Col>
      </Row>
      <CanvasContainer ref={containerRef}>
        <StyledStage
          ref={stageRef}
          onMouseDown={handleStageMouseDown}
          onTouchStart={handleStageMouseDown}
        >
          <Layer>
            {backgroundImage && <Image image={backgroundImage} width={1500} height={500} />}
            {stickerObjects.map(({ id, image, x, y }) => (
              <Image
                key={id}
                name={id}
                image={image}
                x={x}
                y={y}
                width={100}
                height={100}
                draggable
                onClick={() => setSelectedShapeName(id)}
              />
            ))}
            {selectedShapeName && (
              <Transformer
                ref={trRef}
                boundBoxFunc={(oldBox, newBox) => {
                  if (newBox.width < 50 || newBox.height < 50) {
                    return oldBox;
                  }
                  return newBox;
                }}
              />
            )}
            <Text
              text={text}
              x={50}
              y={50}
              fontSize={30}
              draggable
              name="text"
              onClick={() => setSelectedShapeName('text')}
            />
          </Layer>
        </StyledStage>
        <FooterText>Made with nubmaker.lol</FooterText>
      </CanvasContainer>
      <MobileView>
        <ButtonContainer>
          <img src={"/img/Buttons/Forward.png"} style={{ width: "10%", borderRadius: "10%", backgroundColor: "#5E92F5" }} onClick={moveForward}/>
          <img src={"/img/Buttons/Backward.png"} style={{ width: "10%", borderRadius: "10%", backgroundColor: "#5E92F5" }} onClick={moveBackward}/>
          <img src={"/img/Buttons/Mirror.png"} style={{ width: "10%", borderRadius: "10%", backgroundColor: "#5E92F5" }} onClick={flipHorizontal}/>
          <img src={"/img/Buttons/Duplicate.png"} style={{ width: "10%", borderRadius: "10%", backgroundColor: "#5E92F5" }} onClick={duplicate}/>
          <img src={"/img/Buttons/Trash.png"} style={{ width: "10%", borderRadius: "10%", backgroundColor: "#5E92F5" }} onClick={deleteObject}/>
          <img src={"/img/Buttons/Save.png"} style={{ width: "10%", borderRadius: "10%", backgroundColor: "#5E92F5" }} onClick={handleExport}/>
        </ButtonContainer>
      </MobileView>
    </div>
  );
};

export default CanvasComponent;
