import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const importAll = (r) => r.keys().map(r);

const stickers = importAll(require.context('../../public/img/Stickers', false, /\.(png|jpe?g|svg)$/));

const StickerCell = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  cursor: pointer;
`;

const StickerImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
`;

const StickerSelector = ({ addSticker }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const stickersPerPage = 8;
  const totalPages = Math.ceil(stickers.length / stickersPerPage);

  const startIndex = currentPage * stickersPerPage;
  const currentStickers = stickers.slice(startIndex, startIndex + stickersPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Container style={{fontFamily: 'Lilita One', textAlign: "center"}}>
      <h3>Stickers</h3>
      <Row>
        {currentStickers.map((sticker, index) => (
          <Col key={index} xs={6} md={6} className="mb-3">
            <StickerCell onClick={() => addSticker(sticker)}>
              <StickerImg
                src={sticker}
                alt={`Sticker ${index + 1}`}
              />
            </StickerCell>
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-between">
        <Button onClick={handlePrevPage} disabled={currentPage === 0}>
        ⇦
        </Button>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
        ⇨
        </Button>
      </div>
    </Container>
  );
};

export default StickerSelector;
