import React, { useState } from 'react';
import StickerSelector from './Components/StickerSelector';
import BackgroundSelector from './Components/BackgroundSelector';
import CanvasComponent from './Components/Canvas';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';

const WrapperDiv = styled.div`
  background-color: #B7D9FE;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const WrapperDivMobile = styled.div`
  background-color: #B7D9FE;
  border-radius: 10px;
  padding: 10px;
  margin: 2.5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const Ear = styled.div`
  width: 20%;
  aspect-ratio: 1/1;
  background-color: #B7D9FE;
  position: absolute;
  top: -10%;
  border-radius: 10%;
  transform: rotate(45deg);

`;

const LeftEar = styled(Ear)`
  left: 5%;
`;

const RightEar = styled(Ear)`
  right: 5%;
`;

const FullHeightContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const App = () => {
  const [stickerObjects, setStickerObjects] = useState([]);
  const [background, setBackground] = useState(null);
  const [text, setText] = useState('');

  const addSticker = (stickerUrl) => {
    const newId = `sticker-${Date.now()}`;
    const img = new window.Image();
    img.src = stickerUrl;
    img.onload = () => {
      setStickerObjects([...stickerObjects, { id: newId, image: img, x: 50, y: 50 }]);
    };
  };

  return (
    <>
      <img src={"/img/Nubmaker logo.png"} alt="Nubmaker Logo" style={{marginBottom: isMobile ? "100" : 0}}/>
      <FullHeightContainer fluid>
        <BrowserView>
          <Row className="w-100">
            <Col xs={12} md={2}>
              <WrapperDiv>
                <StickerSelector addSticker={addSticker} />
              </WrapperDiv>
            </Col>
            <Col xs={12} md={8}>
              <WrapperDiv>
                <LeftEar />
                <RightEar />
                <div style={{zIndex: "3"}}>
                <CanvasComponent
                  stickerObjects={stickerObjects}
                  setStickerObjects={setStickerObjects}
                  background={background}
                  text={text}
                  setText={setText}
                />
                </div>
              </WrapperDiv>
            </Col>
            <Col xs={12} md={2}>
              <WrapperDiv>
                <BackgroundSelector setBackground={setBackground} />
              </WrapperDiv>
            </Col>
          </Row>
        </BrowserView>

        <MobileView>
          <div style={{ width: "10", height: "100px" }}></div>
          <Row className="w-100">
            <WrapperDivMobile>
              <LeftEar />
              <RightEar />
              <div style={{zIndex: "3"}}>
              <CanvasComponent
                stickerObjects={stickerObjects}
                setStickerObjects={setStickerObjects}
                background={background}
                text={text}
                setText={setText}
              />
              </div>
            </WrapperDivMobile>
          </Row>
          <Row>
            <Col xs={6} md={6}>
              <WrapperDiv>
                <StickerSelector addSticker={addSticker} />
              </WrapperDiv>
            </Col>
            <Col xs={6} md={6}>
              <WrapperDiv>
                <BackgroundSelector setBackground={setBackground} />
              </WrapperDiv>
            </Col>
          </Row>
        </MobileView>
      </FullHeightContainer>
    </>
  );
};

export default App;